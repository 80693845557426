<template>
  <div class="page">
    <Navbar title="摊位租赁" type="SMP" />
    <van-search
      v-model="roAddress"
      show-action
      label="地址"
      placeholder="请输入地址关键词"
      @search="retrieveSiteList"
    >
      <template #action>
        <div @click="retrieveSiteList">搜索</div>
      </template>
    </van-search>
    <van-row class="title">
      <van-col :span="24"><van-icon name="notes-o" /> 摊位列表</van-col>
    </van-row>
    <van-row class="site" v-for="site in sites" :key="site.code">
      <van-col :span="18">
        <div class="name">
          <van-icon name="shop-o" /> {{ site.name }}({{ site.code }})
        </div>
        <div class="address">
          <van-icon name="location-o" /> {{ site.address }}
        </div>
        <div class="time fc-orange">
          <van-icon name="flag-o" /> 陕西省-西安市
        </div>
      </van-col>
      <van-col :span="6" class="order fc-cyan"
        ><van-button
          plain
          :color="COLOR"
          size="mini"
          @click="hireValidate(site.code, site.masterPhone)"
          icon="cart-o"
          >申请租赁</van-button
        ></van-col
      >
    </van-row>
    <van-row v-if="sites.length <= 0">
      <van-col :span="24">
        <div>
          <van-image
            width="103"
            height="103"
            :src="require('../../../assets/images/home/no-record.png')"
          ></van-image>
        </div>
      </van-col>
    </van-row>
    <van-popup
      :show="validateShow"
      closeable
      close-icon="close"
      position="bottom"
      @click-close-icon="validateClose"
      :style="{ height: '300px' }"
    >
      <div class="validate">
        <van-field
          v-model="site.phoneDis"
          label="预留手机"
          type="tel"
          placeholder="请输入手机号"
          readonly="true"
          :rules="[{ required: true, message: '请填写手机号码' }]"
        >
          <template #button>
            <van-button
              size="mini"
              v-if="sendBtnTime > 0"
              :disabled="true"
              :color="COLOR"
              @click="sendValidate()"
              >发送验证码({{ sendBtnTime }})</van-button
            >
            <van-button
              size="mini"
              v-if="sendBtnTime <= 0"
              :color="COLOR"
              @click="sendValidate()"
              >发送验证码</van-button
            >
          </template></van-field
        >
        <van-field
          v-model="site.validate"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
          :rules="[{ required: true, message: '请填写短信验证码' }]"
        >
        </van-field>
        <van-row>
          <van-col offset="8" span="8" class="subbtn">
            <van-button
              block
              type="primary"
              @click="hireSite"
              :color="COLOR"
              size="mini"
            >
              提交租赁
            </van-button></van-col
          >
        </van-row>
      </div>
    </van-popup>
    <Tabbar active="site" />
    <Share ref="share" />
  </div>
</template>
<script>
import Navbar from '../../module/common/Navbar.vue'
import Tabbar from '../common/Tabbar.vue'
import Share from '../../module/common/Share.vue'
import { Search, Icon, Button, Popup, Field } from 'vant'
export default {
  components: {
    Navbar: Navbar,
    Tabbar: Tabbar,
    Share: Share,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Field.name]: Field
  },
  data () {
    return {
      sendBtnTime: 0,
      roAddress: '',
      loadingShow: false,
      returnBack: 'N',
      validateState: false,
      validateShow: false,
      keyword: '',
      sites: [],
      site: { phone: '', phoneDis: '', validate: '', code: '' }
    }
  },
  mounted () {
    this.retrieveSiteList()
    this.$refs.share.default('seller')
  },
  methods: {
    onSearch () {
    },
    async retrieveSiteList () {
      var pd = { regionCode: '610', current: 1, size: 1000, roAddress: this.roAddress }
      const { data: res } = await this.$http.post(this.BMS_URL + '/media/site/retrieveHireSiteList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.sites = res.data
      }
    },
    validateClose () {
      this.validateShow = false
    },
    hireValidate (code, phone) {
      this.site.phone = phone
      this.site.code = code
      var phoneDis = phone.substring(0, 3) + '****' + phone.substring(7, 11)
      this.site.phoneDis = phoneDis
      this.validateShow = true
    },
    async hireSite () {
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var pd = { sellerCode: seller, siteCode: this.site.code, phone: this.site.phone, validate: this.site.validate }
      const { data: res } = await this.$http.post(this.BMS_URL + '/media/site/hireSite', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$router.push({ path: '/seller/site' })
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: res.msg
        })
      }
    },
    async sendValidate () {
      var pd = { phone: this.site.phone }
      var that = this
      const { data: res } = await this.$http.post(this.BMS_URL + '/media/site/sendValidate', this.$qs.stringify(pd))
      if (res.status === '200') {
        that.validateState = true
        that.sendBtnTime = 60
        that.countdown()
      }
    },
    countdown () {
      var that = this
      setTimeout(function () {
        that.sendBtnTime--
        if (that.sendBtnTime > 0) {
          that.countdown()
        }
      }, 1000)
    }
  }
}
</script>
<style lang="less" scoped>
.title {
  height: 30px;
  line-height: 30px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding-left: 20px;
  border-bottom: 1px solid #eee;
}
.site {
  text-align: left;
  padding: 10px 0px 10px 10px;
  border-bottom: 1px solid #eee;
  .name {
    height: 20px;
    line-height: 20px;
    font-weight: 14px;
    font-weight: 600;
  }
  .address {
    height: 20px;
    line-height: 20px;
  }
  .time {
    height: 20px;
    line-height: 20px;
  }
  .order {
    height: 60px;
    line-height: 60px;
  }
}
.validate {
  margin-top: 35px;
  .subbtn {
    margin-top: 10px;
  }
}
</style>
